import { useCustomersSearchQuery } from 'api/driverama/customer/search'
import { useKeyDown } from 'driverama-core/utils/hooks'
import { Maybe } from 'driverama-core/utils/types'
import { useEffect, useRef, useState } from 'react'
import { useDebounce } from 'react-use'

export function useSearch(value: Maybe<string>) {
  const [search, setSearch] = useState(value || '')
  const [debouncedSearch, setDebouncedSearch] = useState(search)

  useEffect(() => {
    if (value?.length) {
      setSearch(value)
    }
  }, [value])

  useDebounce(
    () => {
      setDebouncedSearch(search)
    },
    500,
    [search]
  )

  return {
    debouncedSearch,
    search,
    setSearch
  }
}

interface UseEmailSelectProps {
  debouncedSearch: string
  search: string
  setSearch: (newSearchValue: string) => void
  onChange: (customerId: string) => void
  onCreateNew: (customerEmail: string) => void
  onExistingUserSelect: (customerId: string) => void
}

const ITEM_SIZE = 43
const CREATE_NEW_ITEM_OFFSET = 1

export function useEmailSelect(props: UseEmailSelectProps) {
  const [focused, setFocused] = useState<number | null>(null)
  const listRef = useRef<HTMLDivElement>(null)

  const { data, isLoading, isError } = useCustomersSearchQuery(
    {
      fullText: props.debouncedSearch,
      ids: []
    },
    {
      enabled: !!props.debouncedSearch
    }
  )

  useEffect(() => {
    setFocused(null)
  }, [data?.content])

  const allEmail = data?.content.map(customer => customer.email)
  const emailExactMatchSearch = allEmail?.includes(props.search)

  function onArrowPress(direction: 'down' | 'up') {
    const current = focused

    if (data?.content.length) {
      if (direction === 'down') {
        const newFocused =
          current === null
            ? 0
            : current + 1 < data.content.length + CREATE_NEW_ITEM_OFFSET
            ? current + 1
            : data.content.length + CREATE_NEW_ITEM_OFFSET - 1

        scrollList(newFocused)
        setFocused(newFocused)
      } else {
        const newFocused =
          current === null
            ? data.content.length + CREATE_NEW_ITEM_OFFSET - 1
            : current - 1 > 0
            ? current - 1
            : 0

        scrollList(newFocused)
        setFocused(newFocused)
      }
    }
  }

  function scrollList(focusedIndex: number) {
    if (listRef.current) {
      listRef.current.scrollTo({
        behavior: 'smooth',
        top: focusedIndex * ITEM_SIZE - ITEM_SIZE
      })
    }
  }

  function onEnterPick(focusedIndex: number | null) {
    if (focusedIndex !== null && data?.content.length) {
      const customer =
        data?.content[
          focusedIndex + (emailExactMatchSearch ? 0 : -CREATE_NEW_ITEM_OFFSET)
        ]

      if (customer) {
        props.onChange(customer.id)

        if (customer.email) {
          props.setSearch(customer.email)

          if (emailExactMatchSearch) {
            props.onCreateNew(customer.email)
          } else {
            props.onExistingUserSelect(customer.id)
          }
        }
      }
    }
  }

  useKeyDown('Enter', () => onEnterPick(focused))
  useKeyDown('ArrowDown', () => onArrowPress('down'))
  useKeyDown('ArrowUp', () => onArrowPress('up'))

  return {
    listRef,
    emailExactMatchSearch,
    focused,
    data,
    isLoading,
    isError
  }
}
