import { css } from '@emotion/react'
import { Placement } from '@popperjs/core'
import { Dropdown } from 'driverama-core/components/dropdown/Dropdown'
import { FormControl } from 'driverama-core/components/formControl/FormControl'
import { Spinner } from 'driverama-core/components/spinner/Spinner'
import { size } from 'driverama-core/styles/spacing'
import { isNotNil } from 'driverama-core/utils/types'
import Fuse from 'fuse.js'
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { SInputWrapper } from '../../../components/form/FormControl'
import { useSearch } from '../../customer/emailSelect/CustomerFormEmailSelect.utils'
import {
  dropdownStyles,
  SIconChevronDown,
  SItem
} from './SelectAutocomplete.styled'
import {
  EMPTY_ITEM,
  ExtraItemType,
  isEmptyItem,
  isNewItem,
  Item,
  NEW_ITEM,
  useAutocompleteSelect
} from './SelectAutocomplete.utils'

import FuseResult = Fuse.FuseResult

interface AutocompleteItemProps {
  children: ReactNode
  focused: boolean
  onSelect: () => void
}

function AutocompleteItem(props: AutocompleteItemProps) {
  return (
    <SItem focused={props.focused} onClick={props.onSelect}>
      {props.children}
    </SItem>
  )
}

interface SelectAutocompleteProps<T> {
  error?: string
  label?: string
  data?: ReadonlyArray<T>
  item: (values: T) => ReactElement<{ children: ReactNode }>
  emptyItem?: () => ReactElement<{ children: ReactNode }>
  fuseOptions: Fuse.IFuseOptions<T>
  onSelect: (item: Item<T>, value: string) => void
  value?: string
  onSearch?: (value: string) => void
  loading?: boolean
  newItemLabel?: string
  input?: ReactNode
  disabled?: boolean
  onReset?: () => void
  icon?: boolean
  dropdownPlacement?: Placement
}

export function SelectAutocomplete<T>({
  label,
  error,
  data,
  fuseOptions,
  item,
  onSelect,
  value,
  onSearch,
  loading = false,
  newItemLabel,
  input,
  disabled,
  onReset,
  emptyItem,
  icon,
  dropdownPlacement
}: SelectAutocompleteProps<T>) {
  const { debouncedSearch, search, setSearch } = useSearch(value)
  const [opened, setOpened] = useState(false)
  const fuse = useMemo(() => new Fuse(data ?? [], fuseOptions), [
    data,
    fuseOptions
  ])

  const found = fuse.search(debouncedSearch, {
    limit: 50
  })

  const isOpened = opened && search.length > 0

  useEffect(() => {
    if (!value?.length) {
      setSearch('')
    }
  }, [setSearch, value])

  const options: Array<FuseResult<T> | ExtraItemType> = [
    emptyItem ? EMPTY_ITEM : undefined,
    ...found,
    newItemLabel ? NEW_ITEM : undefined
  ].filter(isNotNil)

  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedSearch)
    }
  }, [onSearch, debouncedSearch])

  const inputRef = useRef<HTMLInputElement>(null)
  const handleSelect = useCallback(
    item => {
      if (isEmptyItem(item)) {
        setSearch('')
      }
      inputRef?.current?.click()
      onSelect(item, search)
    },
    [onSelect, search, setSearch]
  )

  const { listRef, focused } = useAutocompleteSelect(options, {
    onSelect: handleSelect
  })

  return (
    <SInputWrapper>
      <Dropdown
        padding="none"
        css={css`
          display: ${!!search.trim() ? 'block' : 'none'};
          ${dropdownStyles}
        `}
        onOpen={() => setOpened(true)}
        fullWidth
        containerRef={listRef}
        onClose={() => {
          setOpened(false)
          if (onReset && !search.length && value?.length) {
            onReset()
          } else {
            if (value) {
              setSearch(value)
            }
          }
        }}
        placement={dropdownPlacement}
        trigger={
          input ? (
            input
          ) : (
            <FormControl label={label} error={error} isReadOnly={disabled}>
              <input
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
                ref={inputRef}
                disabled={disabled}
              />

              {!!icon && <SIconChevronDown open={isOpened} />}

              {!!loading && (
                <Spinner variant="x-small" css={{ marginRight: size(2) }} />
              )}
            </FormControl>
          )
        }
      >
        {search.length > 0 && (
          <ul>
            {options.map((x, key) => {
              if (isEmptyItem(x)) {
                return (
                  <AutocompleteItem
                    key={key}
                    focused={focused === key}
                    onSelect={() => handleSelect(x)}
                  >
                    {emptyItem?.()}
                  </AutocompleteItem>
                )
              }

              if (isNewItem(x)) {
                return (
                  <AutocompleteItem
                    key={key}
                    focused={focused === key}
                    onSelect={() => handleSelect(x)}
                  >
                    {newItemLabel}
                  </AutocompleteItem>
                )
              }

              return (
                <AutocompleteItem
                  key={key}
                  focused={focused === key}
                  onSelect={() => handleSelect(x.item)}
                >
                  {item(x.item)}
                </AutocompleteItem>
              )
            })}
          </ul>
        )}
      </Dropdown>
    </SInputWrapper>
  )
}
