import styled from '@emotion/styled'
import { color, radius, time, weight } from 'driverama-core/styles/variables'
import { size } from 'driverama-core/styles/spacing'
import { css } from '@emotion/react'
import IconChevronDown from 'driverama-core/images/icons/IconChevronDown.svg'

export const SItem = styled.li<{ focused?: boolean }>`
  position: relative;
  background: ${color('white')};
  transition: background ${time('fast')} ease-in-out;
  padding: ${size(3)} ${size(4)};
  cursor: pointer;

  &:hover,
  &:focus {
    background: ${color('night-l-700')};
  }

  ${({ focused }) =>
    focused &&
    css`
      background: ${color('night-l-700')};
    `}

  mark {
    background-color: transparent;
    font-weight: ${weight('bold')};
    color: ${color('night-d-100')};
  }
`

export const dropdownStyles = css`
  border-radius: ${radius('input')};
  border: 2px solid ${color('night-l-650')};
`

export const SIconChevronDown = styled(IconChevronDown)<{ open: boolean }>`
  position: absolute;
  right: ${size(2)};

  transition: all ${time('control')} ease-in-out;
  color: ${color('night-l-100')};

  ${({ open }) => open && `transform: rotate(180deg);`}
`
