import Fuse from 'fuse.js'
import { useEffect, useRef, useState } from 'react'
import { useKeyDown } from 'driverama-core/utils/hooks'

const ITEM_SIZE = 48

export interface ExtraItemType {
  type: 'EMPTY_ITEM' | 'NEW_ITEM'
}

export type Item<T> = T | ExtraItemType

export const NEW_ITEM: ExtraItemType = {
  type: 'NEW_ITEM'
}

export const EMPTY_ITEM: ExtraItemType = {
  type: 'EMPTY_ITEM'
}

export function isNewItem<T>(item: Item<T>): item is typeof NEW_ITEM {
  return (item as typeof NEW_ITEM).type === 'NEW_ITEM'
}

export function isEmptyItem<T>(item: Item<T>): item is typeof EMPTY_ITEM {
  return (item as typeof EMPTY_ITEM).type === 'EMPTY_ITEM'
}

export function isExtraItem<T>(item: Item<T>): item is ExtraItemType {
  return isNewItem(item) || isEmptyItem(item)
}

interface UseAutocompleteSelectProps<T> {
  onSelect: (value: Item<T>) => void
}

export function useAutocompleteSelect<T>(
  found: Array<Fuse.FuseResult<T> | ExtraItemType>,
  props: UseAutocompleteSelectProps<T>
) {
  const [focused, setFocused] = useState<number | null>(0)
  const listRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setFocused(null)
  }, [])

  function onArrowPress(direction: 'down' | 'up') {
    const current = focused
    if (found.length) {
      if (direction === 'down') {
        const newFocused =
          current === null
            ? 0
            : current + 1 < found.length
            ? current + 1
            : found.length

        scrollList(newFocused)
        setFocused(newFocused)
      } else {
        const newFocused =
          current === null ? 0 : current - 1 > 0 ? current - 1 : 0

        scrollList(newFocused)
        setFocused(newFocused)
      }
    }
  }

  function scrollList(focusedIndex: number) {
    if (listRef.current) {
      listRef.current.scrollTo({
        behavior: 'smooth',
        top: focusedIndex * ITEM_SIZE - ITEM_SIZE
      })
    }
  }

  function onEnterPick(focusedIndex: number | null) {
    if (focusedIndex !== null && found.length) {
      const item = found[focusedIndex]

      if (item) {
        listRef?.current?.click() // Simulate click to close list items
        props.onSelect(isExtraItem(item) ? item : item.item)
      }
    }
  }

  useKeyDown('Enter', () => onEnterPick(focused), {
    preventDefault: true
  })
  useKeyDown('ArrowDown', () => onArrowPress('down'))
  useKeyDown('ArrowUp', () => onArrowPress('up'))

  return {
    listRef,
    wrapperRef,
    focused
  }
}
